import {
    makeStyles,
    tokens,
    shorthands
} from '@fluentui/react-components';

const CARD_WIDTH = '360px';
const BRAND_COLOR = '#1E2359';
const ON_WIDTH_MAX_L = '@media only screen and (max-width: 640px)';
const ON_WIDTH_MAX_XL = '@media only screen and (max-width: 1024px)';

export const useOncologieStyles = makeStyles({
    topBar: {
        width: '100%',
        height: '48px',
        backgroundColor: BRAND_COLOR,
        color: tokens.colorNeutralForegroundOnBrand,
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        [ON_WIDTH_MAX_L]: {
            height: 'auto',
            justifyContent: 'left',
            flexDirection: 'column',
            rowGap: tokens.spacingVerticalS,
            paddingTop: tokens.spacingVerticalXS,
            paddingBottom: tokens.spacingVerticalXS
        }
    },
    topBarLink: {
        color: tokens.colorNeutralForegroundOnBrand,
        '&:hover': {
            color: tokens.colorNeutralForeground3BrandHover
        }
    },
    topBarSearch: {
        width: '400px',
        maxWidth: '90vw'
    },
    headerTitle: {
        width: '100%',
        height: '48px',
        color: tokens.colorNeutralForegroundOnBrand,
        textAlign: 'center',
        backgroundImage: 'url(/cells.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        paddingTop: tokens.spacingVerticalXXXL,
        paddingBottom: tokens.spacingVerticalXXXL
    },
    badgeCount: {
        bottom: '2px'
    },
    articleTable: {
        ...shorthands.borderTop(tokens.strokeWidthThin, 'solid', tokens.colorNeutralStroke1),
        borderBottomStyle: 'none',
        borderLeftStyle: 'none',
        borderRightStyle: 'none',
    },
    specialitiesAccordionPanel: {
        marginBottom: '0px',
        marginLeft: '0px',
        marginRight: '0px'
    },
    specialitiesAccordionItem: {
        ...shorthands.border(tokens.strokeWidthThin, 'solid', tokens.colorNeutralStroke1),
        marginBottom: '-1px'
    },
    specialitiesAccordionHeader: {
        backgroundColor: tokens.colorNeutralBackground2,
    },
    row: {
        paddingTop: tokens.spacingVerticalS,
        paddingBottom: tokens.spacingVerticalS,
        '&:nth-child(odd)': {
            backgroundColor: tokens.colorNeutralBackground4
        },
        '&:last-child': {
            borderBottomStyle: 'none'
        }
    },
    contentContainer: {
        width: '70%',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingTop: tokens.spacingHorizontalL,
        paddingLeft: tokens.spacingHorizontalXXL,
        paddingRight: tokens.spacingHorizontalXXL,
        paddingBottom: tokens.spacingHorizontalXXL,
        display: 'flex',
        flexDirection: 'column',
        [ON_WIDTH_MAX_XL]: {
            width: 'auto',
        }
    },
    card: {
        height: '100%',
        '& li': {
            display: 'grid',
            gridTemplateColumns: 'auto 1fr auto',
            gridGap: '4px',
            alignItems: 'center',
        },
        '& ul': {
            ...shorthands.margin(0),
            ...shorthands.padding(0),
            paddingTop: tokens.spacingVerticalXXS,
            paddingLeft: tokens.spacingHorizontalXXS
        },
        '& p': {
            ...shorthands.margin(0),
        }
    },
    twoColumnsContainer: {
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fit, ${CARD_WIDTH})`,
        gridGap: '1rem',
        justifyContent: 'space-around'
    },
    data: {
        display: 'flex',
        marginBottom: tokens.spacingVerticalXL,
        rowGap: tokens.spacingHorizontalXS,
        columnGap: '10px',
        '& > div:first-child': {
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: '140px'
        },
        '& > div:nth-child(2n)': {
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: 'auto'
        },
        [ON_WIDTH_MAX_XL]: {
            flexDirection: 'column',
            '& > div:first-child': {
                flexBasis: 'auto'
            }
        }
    },
    tagGroup: {
        display: 'inline-flex',
        flexWrap: 'wrap',
        rowGap: tokens.spacingHorizontalXS
    },
    contactsContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, 320px)',
        rowGap: tokens.spacingVerticalXL,
        columnGap: tokens.spacingHorizontalXL
    },
    articleActions: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: tokens.spacingHorizontalL
    },
    skeletonTitle: {
        marginBottom: tokens.spacingVerticalXXL,
        marginTop: tokens.spacingVerticalXXL
    }
});