import {
    Card,
    Title2,
    Body1,
    Text,
    CardHeader,
} from '@fluentui/react-components';
import {
    MailRegular,
    ClockRegular,
    PrintRegular,
    CallRegular
} from '@fluentui/react-icons';

import { useOncologieStyles } from './styles';

export default function RRO() {
    const classes = useOncologieStyles();

    return (
        <>
            <Title2 as='h2' block>Réseau Romand d'Oncologie</Title2>
            <Body1 as='p' block>
                Le Réseau Romand d'Oncologie (RRO) est une ressource pour tout oncologue en charge de patient-e-s atteint-e-s d'un cancer.
                <br />
                Il offre à tout oncologue une plateforme de discussion et d'interprétation des données moléculaires complexes pour faciliter la prise en charge thérapeutique des patients.
                <br />
                Il permet de proposer aux patients-e-s des solutions thérapeutiques de pointe, tout en respectant le lien privilégié qu'ils entretiennent avec leur médecin traitant.
            </Body1>
            <div className={classes.twoColumnsContainer}>
                <Card className={classes.card}>
                    <CardHeader
                        header={<Text weight='semibold'>Antenne Lausanne</Text>}
                    />
                    <Body1 as='p'>Secrétariat :</Body1>
                    <ul>
                        <li><ClockRegular />Lun-ven 9h - 17h</li>
                        <li><CallRegular />Tél. +41 21 314 22 88</li>
                        <li><PrintRegular />Fax +41 21 314 78 67</li>
                    </ul>
                </Card>
                <Card className={classes.card}>
                    <CardHeader
                        header={<Text weight='semibold'>Antenne Genève</Text>}
                    />
                    <Body1 as='p'>Secrétariat Tumor boards :</Body1>
                    <ul>
                        <li><ClockRegular />Lun-ven 8h - 17h</li>
                        <li><CallRegular />Tél. +41 22 372 95 77</li>
                        <li><PrintRegular />Fax +41 22 372 95 30</li>
                        <li><MailRegular />tumorboards.secretariat@hug.ch</li>
                    </ul>
                </Card>
            </div>
        </>
    )
}