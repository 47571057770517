import { FluentProvider, webLightTheme } from '@fluentui/react-components';
import { Navigate, Route, Routes, BrowserRouter } from "react-router-dom";

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

import Layout from './components/Layout';
import HomePage from './components/HomePage';
import ArticlePage from './components/Article';

const queryClient = new QueryClient();

function App() {
  return (
    <FluentProvider
      style={{ minHeight: '100vh' }}
      theme={{
        ...webLightTheme,
        colorNeutralBackground3: '#f4f4f4'
      }}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<HomePage />} />
              <Route path="/etudes/:url" element={<ArticlePage />} />
              <Route path="*" element={<Navigate to={"/"} />}></Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </FluentProvider>
  );
}

export default App;
