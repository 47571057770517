import { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import {
    Text,
    Title2,
    Body1,
    Body1Stronger,
    Skeleton,
    SkeletonItem,
    Link,
    Button,
    Card,
    CardHeader,
    Tag,
    TagGroup
} from '@fluentui/react-components';
import { PrintRegular, MailRegular, CallRegular, BuildingRegular } from '@fluentui/react-icons';
import { useQuery } from '@tanstack/react-query'

import { useOncologieStyles } from './styles';
import config from '../config';

interface IArticle {
    name: string,
    speciality: string[],
    indication: string,
    sponsor: string,
    phase: string[],
    line: string,
    treatment: string,
    specificities: string,
    site: string[],
    co_investigators: { LookupId: number }[],
    coordinators: { LookupId: number }[],
    principal_investigators: { LookupId: number }[],
    primary_objective: string,
    inclusion_criteria: string,
    exclusion_criteria: string
}

interface IContact {
    id: number,
    firstname: string,
    lastname: string,
    institution: string,
    department: string,
    address: string,
    city: string,
    zipcode: string,
    tel: string,
    email: string
}

export default function ArticlePage() {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <>
            <Link onClick={_ => {
                if (location.key !== 'default')
                    navigate(-1);
                else
                    navigate('/');
            }}>← Retour</Link>
            <Article />
        </>
    )
}

function Article() {
    const navigate = useNavigate();
    const params = useParams();
    const classes = useOncologieStyles();

    const [mailto, setMailto] = useState('');

    // Get Aricle from url
    const { data: article, status } = useQuery({
        queryKey: ['article', params.url],
        initialData: {
            name: '',
            speciality: [],
            indication: '',
            sponsor: '',
            phase: [],
            line: '',
            treatment: '',
            specificities: '',
            site: [],
            co_investigators: [],
            coordinators: [],
            principal_investigators: [],
            primary_objective: '',
            inclusion_criteria: '',
            exclusion_criteria: ''
        } as IArticle,
        queryFn: async () => {
            return await (await fetch(`${config.apiBase}/article?url=${params.url}`)).json() as IArticle;
        },
    });

    if (status === 'error') return (
        <p>Cet article n'existe pas. <Link onClick={_ => navigate('/')}>Retourner à l'accueil.</Link></p>
    );

    // Generate email text
    useEffect(() => {
        let primary_objective = document.createElement('div'); primary_objective.innerHTML = article.primary_objective;
        let body: string = `See full article: ${window.location}
Primary objective: ${primary_objective.innerText}

Specialty(s): ${article.speciality.join(', ')}

Indication: ${article.indication}

Phase(s): ${article.phase.join(', ')}

Line: ${article.line}

Treatment: ${article.treatment}

Specificities: ${article.specificities || 'None'}`;
        setMailto('mailto:?subject=' + encodeURIComponent(`Étude d'oncologie : ${article.name}`) + '&body=' + encodeURIComponent(body));

    }, [article]);

    if (article.name === '') return (
        <>
            <Skeleton className={classes.skeletonTitle}>
                <SkeletonItem shape='rectangle' size={96} />
            </Skeleton>
            <Skeleton className={classes.data}>
                <SkeletonItem shape='rectangle' size={16} />
                <SkeletonItem shape='rectangle' size={16} />
            </Skeleton>
            <Skeleton className={classes.data}>
                <SkeletonItem shape='rectangle' size={16} />
                <SkeletonItem shape='rectangle' size={16} />
            </Skeleton>
            <Skeleton className={classes.data}>
                <SkeletonItem shape='rectangle' size={16} />
                <SkeletonItem shape='rectangle' size={16} />
            </Skeleton>
            <Skeleton className={classes.data}>
                <SkeletonItem shape='rectangle' size={16} />
                <SkeletonItem shape='rectangle' size={16} />
            </Skeleton>
            <Skeleton className={classes.data}>
                <SkeletonItem shape='rectangle' size={16} />
                <SkeletonItem shape='rectangle' size={16} />
            </Skeleton>
            <Skeleton className={classes.data}>
                <SkeletonItem shape='rectangle' size={16} />
                <SkeletonItem shape='rectangle' size={16} />
            </Skeleton>
            <Skeleton className={classes.data}>
                <SkeletonItem shape='rectangle' size={16} />
                <SkeletonItem shape='rectangle' size={16} />
            </Skeleton>
        </>
    );

    return (
        <>
            <div id='section-to-print'>
                <Title2 as='h2' block>{article.name}</Title2>
                <DataHTML field='Primary objective' html={article.primary_objective} />
                <DataTags field='Specialty(s)' values={article.speciality} />
                <DataText field='Indication' value={article.indication} />
                <DataTags field='Phase(s)' values={article.phase} />
                <DataText field='Line' value={article.line} />
                <DataText field='Treatment' value={article.treatment} />
                <DataText field='Specificities' value={article.specificities} />
                <DataHTML field='Inclusion criteria' html={article.inclusion_criteria} />
                <DataHTML field='Exclusion criteria' html={article.exclusion_criteria} />
                <DataTags field='Site(s)' values={article.site} />
                <DataText field='Sponsor' value={article.sponsor} />
                <div className={classes.data}>
                    <div><Body1Stronger>Contact(s)</Body1Stronger></div>
                    <div className={classes.contactsContainer}>
                        {article.principal_investigators && article.principal_investigators.map(c => (
                            <ContactCard key={c.LookupId} id={c.LookupId} role='Principal investigator' />
                        ))}
                        {article.co_investigators && article.co_investigators.map(c => (
                            <ContactCard key={c.LookupId} id={c.LookupId} role='Co-investigator' />
                        ))}
                        {article.coordinators && article.coordinators.map(c => (
                            <ContactCard key={c.LookupId} id={c.LookupId} role='Coordinator' />
                        ))}
                    </div>
                </div>
            </div>
            <div className={classes.articleActions}>
                <Button appearance='primary' icon={<MailRegular />} onClick={_ => window.open(mailto)}>Envoyer l'étude par email</Button>
                <Button icon={<PrintRegular />} onClick={_ => window.print()}>Imprimer l'étude</Button>
            </div>
        </>
    );
}

function ContactCard({ id = -1, role = '' }) {
    const classes = useOncologieStyles();

    const { data: user } = useQuery({
        queryKey: ['getUser', id],
        queryFn: async () => {
            return await (await fetch(`${config.apiBase}/users?id=${id}`)).json() as IContact;
        },
    });

    if (!user)
        return (
            <Card className={classes.card}>
                <CardHeader
                    header={<SkeletonItem shape='rectangle' />}
                />
            </Card>
        );

    return (
        <Card className={classes.card}>
            <CardHeader
                header={<Text weight='semibold'>{user.firstname} {user.lastname} - {role}</Text>}
            />
            <ul>
                <li><BuildingRegular />{user.institution}</li>
                <li>{user.department}</li>
            </ul>
            <ul>
                <li>{user.address}</li>
                <li>{user.zipcode} {user.city}</li>
            </ul>
            <ul>
                <li><CallRegular />{user.tel}</li>
                <li><MailRegular />{user.email}</li>
            </ul>
        </Card>
    );
}

function DataText({ field = '', value = '' }) {
    const classes = useOncologieStyles();

    if (value === '')
        return (<></>);

    return (
        <div className={classes.data}>
            <div><Body1Stronger>{field}</Body1Stronger></div>
            <div>
                <Body1>{value}</Body1>
            </div>
        </div>
    );
}

function DataTags({ field = '', values = [] as string[] }) {
    const classes = useOncologieStyles();

    if (values && values.length === 0)
        return (<></>);

    return (
        <div className={classes.data}>
            <div><Body1Stronger>{field}</Body1Stronger></div>
            <div>
                <Body1>
                    <TagGroup aria-label={field} role='list' className={classes.tagGroup}>
                        {values.map(value => (
                            <Tag role='listitem' key={value}>{value}</Tag>
                        ))}
                    </TagGroup>
                </Body1>
            </div>
        </div>
    );
}

function DataHTML({ field = '', html = '' }) {
    const classes = useOncologieStyles();

    if (html === '')
        return (<></>);

    return (
        <div className={classes.data}>
            <div><Body1Stronger>{field}</Body1Stronger></div>
            <div>
                <Body1><div dangerouslySetInnerHTML={{ __html: html }} /></Body1>
            </div>
        </div>
    );
}