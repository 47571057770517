import React from 'react';
import { useEffect, useState } from 'react';
import { useSearchParams, Link as RouterLink } from 'react-router-dom';

import {
    Title2,
    Title3,
    Body1,
    Caption1,
    CounterBadge,
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    DataGrid,
    DataGridHeader,
    DataGridRow,
    DataGridBody,
    DataGridHeaderCell,
    DataGridCell,
    createTableColumn,
    TableCellLayout,
    TableColumnDefinition,
    Body1Strong,
    AccordionToggleEventHandler,
    Spinner,
    Tag,
    TagGroup,
    Divider,
    SkeletonItem
} from '@fluentui/react-components';
import { Link } from '@fluentui/react';
import { useQuery } from '@tanstack/react-query'

import config from '../config';
import { useOncologieStyles } from './styles';

interface ISpeciality {
    name: string;
    articles: IArticle[];
}

interface IArticle {
    id: string;
    name: string;
    indication: string;
    site: string[];
    phase: string[];
    url: string;
    searchSummary: string;
}

export default function Specialities() {
    const classes = useOncologieStyles();
    const [searchParams] = useSearchParams();

    const [specialities, setSpecialities] = useState<ISpeciality[]>([]);

    // Func used to set wich Specialities are open by default
    const [openSpecialities, setOpenSpecialities] = React.useState(localStorage.getItem('openSpecialities')?.split(';') || []);
    const handleToggle: AccordionToggleEventHandler<string> = (event, data) => {
        setOpenSpecialities(data.openItems);
        localStorage.setItem('openSpecialities', data.openItems.join(';'));
    };

    // Fetching all articles by specialities
    const { isFetching: isFetchingAll, data: allSpecialities, isFetched: isFetchedAll } = useQuery({
        queryKey: ['articlesBySpecialities'],
        initialData: [] as ISpeciality[],
        refetchOnWindowFocus: false,
        queryFn: async () => {
            // response => Object : Key = Speciality name, Value = Array of articles
            const response = await (await fetch(`${config.apiBase}/articles`)).json();
            return Object.keys(response.articles).sort().map(k => {
                return {
                    name: k,
                    articles: response.articles[k] as IArticle[]
                };
            });
        },
    });

    // Fetching search
    const { isFetching: isFetchingSearch, data: searchResult } = useQuery({
        queryKey: ['search', searchParams.get('q')],
        initialData: null as { id: string, summary: string }[] | null,
        refetchOnWindowFocus: false,
        queryFn: async () => {
            if (searchParams.get('q') && searchParams.get('q') !== '') {
                // result => Array : id of article, summary of where keywords are used (optionnal) 
                const { result } = await (await fetch(`${config.apiBase}/articles/search?q=${searchParams.get('q')}`)).json() as { result: { id: string, summary: string }[] };
                return result;
            } else if (searchParams.get('q') === '') {
                setOpenSpecialities([]);
            }
            return null;
        },
    });

    // Merge all articles and search results
    useEffect(() => {
        if (searchResult === null) {
            setSpecialities(allSpecialities);
        } else {
            const allSpecialitiesTemp = allSpecialities.map((s) => {
                return {
                    ...s,
                    articles: s.articles.reduce((accumulator, currentValue) => {
                        let find = searchResult.find(res => res.id === currentValue.id);
                        if (find) {
                            currentValue.searchSummary = find.summary || '';
                            accumulator.push(currentValue);
                        }
                        return accumulator;
                    }, [] as IArticle[])
                };
            }).filter(s => s.articles.length > 0);

            setSpecialities(allSpecialitiesTemp);
            setOpenSpecialities(allSpecialitiesTemp.map(s => s.name));
        }
    }, [searchResult, allSpecialities]);

    return (
        <>
            {isFetchingAll || isFetchingSearch ? (
                <Spinner style={{ justifyContent: 'start' }} size='tiny' label={<Title2 as='h2' block>Études Oncologie</Title2>} labelPosition='before' />
            ) : (
                <Title2 as='h2' block>Études Oncologie</Title2>
            )}

            {(searchResult !== null && !isFetchingSearch) && (
                <Body1 as='p' block>Recherche : <Tag>{searchParams.get('q')}</Tag></Body1>
            )}

            {(searchResult !== null && searchResult.length == 0 && !isFetchingSearch) && (
                <Body1 as='p' block>Aucune étude disponible pour ce critère de recherche.</Body1>
            )}

            {isFetchingAll && !isFetchedAll && (
                <Accordion>
                    {[...Array(9)].map((e, i) => (
                        <AccordionItem value={i} key={i} className={classes.specialitiesAccordionItem} >
                            <AccordionHeader expandIconPosition='end' as='h3' className={classes.specialitiesAccordionHeader}>
                                <SkeletonItem shape='rectangle' size={32} style={{ width: '400px' }} />
                            </AccordionHeader>
                            <AccordionPanel></AccordionPanel>
                        </AccordionItem>
                    ))}
                </Accordion>
            )}
            <Accordion
                openItems={openSpecialities}
                onToggle={handleToggle}
                multiple
                collapsible
            >
                {specialities.map((s) => (
                    <AccordionItem value={s.name} key={s.name} className={classes.specialitiesAccordionItem} >
                        <AccordionHeader expandIconPosition='end' as='h3' className={classes.specialitiesAccordionHeader}>
                            <Title3>
                                {s.name} <CounterBadge count={s.articles.length} shape='rounded' className={classes.badgeCount} />
                            </Title3>
                        </AccordionHeader>
                        <AccordionPanel className={classes.specialitiesAccordionPanel}>
                            <Articles articles={s.articles} />
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </>
    )
}

function Articles(props: { articles: IArticle[] }) {
    const classes = useOncologieStyles();

    return (
        <DataGrid
            items={props.articles}
            columns={columns}
            sortable
            defaultSortState={{
                sortColumn: 'name',
                sortDirection: 'ascending'
            }}
            getRowId={(item) => item.id}
            focusMode='composite'
            resizableColumns
            columnSizingOptions={{
                name: {
                    minWidth: 150,
                    defaultWidth: 9999
                },
                site: {
                    minWidth: 75
                },
                phase: {
                    minWidth: 60
                }
            }}
            className={classes.articleTable}
        >
            <DataGridHeader>
                <DataGridRow>
                    {({ renderHeaderCell }) => (
                        <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                    )}
                </DataGridRow>
            </DataGridHeader>
            <DataGridBody<IArticle>>
                {({ item, rowId }) => (
                    <DataGridRow<IArticle>
                        key={rowId}
                        className={classes.row}
                    >
                        {({ renderCell }) => (
                            <DataGridCell>
                                {renderCell(item)}
                            </DataGridCell>
                        )}
                    </DataGridRow>
                )}
            </DataGridBody>
        </DataGrid>
    );
}

const columns: TableColumnDefinition<IArticle>[] = [
    createTableColumn<IArticle>({
        columnId: 'name',
        compare: (a, b) => {
            return a.name.localeCompare(b.name);
        },
        renderHeaderCell: () => {
            return 'Name';
        },
        renderCell: (item) => {
            return (
                <TableCellLayout>
                    <Link as={RouterLink} to={`/etudes/${item.url}`} >
                        <Body1Strong>{item.name}</Body1Strong>
                    </Link>
                    <br />
                    <Caption1>{item.indication}</Caption1>
                    {item.searchSummary && item.searchSummary !== '' && (
                        <>
                            <Divider style={{ margin: '2px 0' }} />
                            <Caption1>Résultat de la recherche :<div dangerouslySetInnerHTML={{ __html: item.searchSummary.replaceAll('<ddd/>', '...').replaceAll('<c0>', '<b>').replaceAll('</c0>', '</b>') }} /></Caption1>
                        </>
                    )}
                </TableCellLayout>
            );
        },
    }),
    createTableColumn<IArticle>({
        columnId: 'site',
        compare: (a, b) => {
            return (a.site[0] || '').localeCompare((b.site[0] || ''));
        },
        renderHeaderCell: () => {
            return 'Site';
        },
        renderCell: (item) => {
            return (
                <TableCellLayout>
                    <TagGroup aria-label='Site' role='list' size='small'>
                        {item.site && item.site.map(s => (
                            <Tag role='listitem' key={s}>{s}</Tag>
                        ))}
                    </TagGroup>
                </TableCellLayout>
            );
        },
    }),
    createTableColumn<IArticle>({
        columnId: 'phase',
        compare: (a, b) => {
            return (a.phase[0] || '').localeCompare((b.phase[0] || ''));
        },
        renderHeaderCell: () => {
            return 'Phase';
        },
        renderCell: (item) => {
            return (
                <TableCellLayout>
                    <TagGroup aria-label='Phase' role='list' size='small'>
                        {item.phase && item.phase.map(s => (
                            <Tag role='listitem' key={s}>{s}</Tag>
                        ))}
                    </TagGroup>
                </TableCellLayout>
            );
        },
    })
];