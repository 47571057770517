import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';

import { InputOnChangeData, Title1, Button, Link } from '@fluentui/react-components';
import { SearchBox } from '@fluentui/react-search-preview';
import { DismissRegular } from '@fluentui/react-icons';

import { useOncologieStyles } from './styles';

import config from '../config';

export default function Layout() {
    const classes = useOncologieStyles();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    // Search Handle On Change : change url with a timeout to search keywords
    let searchTimeout: NodeJS.Timeout;
    const searchHandleChange = (ev: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLSpanElement>, data: InputOnChangeData) => {
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(_ => navigate(`/?q=${data.value}`), 300);
    };

    // Search Handle On Key up : search if Enter key 
    const searchHandleKeyUp = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (ev.key === 'Enter') {
            clearTimeout(searchTimeout);
            navigate(`/?q=${(ev.target as HTMLInputElement).value}`);
        }
    };

    return (
        <>
            <header>
                <div className={classes.topBar}>
                    <div>
                        <Link className={classes.topBarLink} href='/'><b>Oncologie</b></Link>
                    </div>
                    <div>
                        <SearchBox
                            role='search'
                            dismiss={
                                <Button
                                    appearance='transparent'
                                    icon={<DismissRegular />}
                                    onClick={() => navigate('/?q=')}
                                />
                            }
                            defaultValue={searchParams.get('q') || ''}
                            className={classes.topBarSearch}
                            placeholder='Rechercher une étude'
                            onChange={searchHandleChange}
                            onKeyUp={searchHandleKeyUp}
                            name='search'
                        />
                    </div>
                    <div></div>
                </div>
                <div className={classes.headerTitle}>
                    <Title1 as='h1'>{config.siteTitle}</Title1>
                </div>
            </header>
            <main className={classes.contentContainer}>
                <Outlet />
            </main>
        </>
    );
}