import { useState } from 'react';

import { TabList, Tab, TabValue } from '@fluentui/react-components';

import RRO from './RRO';
import Teams from './Teams';
import Specialities from './Specialities';

export default function HomePage() {
    const [selectedValue, setSelectedValue] = useState<TabValue>('specialities');

    return (
        <>
            <nav>
                <TabList defaultSelectedValue='specialities' onTabSelect={(e, data) => setSelectedValue(data.value)}>
                    <Tab value='specialities'>Études</Tab>
                    <Tab value='rro'>Réseau Romand d'Oncologie</Tab>
                    <Tab value='teams'>Les équipes</Tab>
                </TabList>
            </nav>
            <section>
                {selectedValue === 'specialities' && <Specialities />}
                {selectedValue === 'rro' && <RRO />}
                {selectedValue === 'teams' && <Teams />}
            </section>
        </>
    )
}